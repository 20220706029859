module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#ffffff","display":"standalone","icon":"src/images/framework/logo.svg","name":"Zample","short_name":"Zample","start_url":"/","theme_color":"#f9e3bf","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"095fea9f5f62a0a5b02b8d105769bb34"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"sv","i18nextOptions":{"defaultNS":"translations","fallbackLng":"sv","interpolation":{"escapeValue":false},"ns":"translations","keySeparator":true},"languages":["en","sv"],"localeJsonSourceName":"locale","redirect":false,"siteUrl":"https://www.zample.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
