import { Script } from 'gatsby';
import * as React from 'react';

import './src/css/custom.scss';
import '@popperjs/core/dist/umd/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

export const wrapRootElement = ({ element }) => (
	<>
		{element}
		<Script data-domain="zample.com" src="https://plausible.io/js/script.js" />
	</>
);
